<template>

  <section id="transporte-dashboard">
    <div class="row">
      <div class="col-12 section-head my-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3 class="section-head-title p-3 ps-0 mb-0">
              Conoce las cifras más importantes de <strong>Transporte</strong>
            </h3>
          </div>
          <div class="col-12 col-lg-6 mb-3">

            <div class="row">
              <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
                Filtrar por periodo:
              </div>
              <div class="col-12 col-lg-9 col-xl-8">
                <div class="row">
                  <div class="col-6">
                    <InputDate 
                      :max="range.date_end"
                      placeHolder="Inicio"
                      @dateEmit="captureDateStart"
                    />
                  </div>
                  <div class="col-6">
                    <InputDate 
                      :min="range.date_start" 
                      placeHolder="Término"
                      @dateEmit="captureDateEnd"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">  
      <div class="row">
        <div class="col-12 col-lg-5">
          <Planificaciones :plan="planificaciones_t"/> 
        </div>
        <div class="col-12 col-lg-7">
          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <EstadisticasPorcentaje :dashboard="global_historica_t"/> 
            </div>
            <div class="col-12 col-lg-6">
              <EstadisticasUsuarios :dashboard="transporte_historico_t"/> 
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <EstadisticasPorcentaje :dashboard="global_activa_t"/> 
            </div>
            <div class="col-12 col-lg-6">
              <EstadisticasUsuarios :dashboard="transporte_activo_t"/>
            </div>
          </div>
        </div>
      </div>
    </template>

    <hr class="border border-gray">
    
    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">   
      <div class="row">
        <div class="col-12 col-lg-5">
          <Planificaciones :plan="planificaciones_a"/>
        </div>
        <div class="col-12 col-lg-7">
          <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <EstadisticasPorcentaje :dashboard="global_historica_a"/>
            </div>
            <div class="col-12 col-lg-6">
              <EstadisticasUsuarios :dashboard="transporte_historico_a"/>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
              <EstadisticasPorcentaje :dashboard="global_activa_a"/>
            </div>
            <div class="col-12 col-lg-6">
              <EstadisticasUsuarios :dashboard="transporte_activo_a"/>
            </div>
          </div>
        </div>
      </div>   
    </template>
  </section>

</template>

<script>
  import moment from "moment";
  import Planificaciones from "./Planificaciones.vue";
  import EstadisticasPorcentaje from "./EstadisticasPorcentaje.vue";
  import EstadisticasUsuarios from "./EstadisticasUsuarios.vue";
  import { mapActions, mapState } from "vuex";

  //FF
  import InputDate from "@/components/forms/InputDate.vue"
  import Loading from "@/components/Loading.vue";

  export default {
    components: {
      Planificaciones,
      EstadisticasPorcentaje,
      EstadisticasUsuarios,

      //FF
      InputDate,
      Loading
    },
    data() {
      return {
        // Ocupacion Terrestre
        global_historica_t: {
          ocupacion: "cargando",
          proximas_fechas: "cargando",
          name: "global histórica",
        },
        global_activa_t: {
          ocupacion: "cargando",
          proximas_fechas: "cargando",
          name: "planificación activa",
        },

        // Ocupación aérea
        global_historica_a: {
          ocupacion: "cargando",
          proximas_fechas: "cargando",
          name: "global histórica",
        },
        global_activa_a: {
          ocupacion: "cargando",
          proximas_fechas: "cargando",
          name: "planificación activa",
        },

        // Transporte histórico
        transporte_historico_t: {
          solicitudes: "cargando",
          porcentaje: "cargando",
          name: "Transporte histórico",
        },
        transporte_activo_t: {
          solicitudes: "cargando",
          porcentaje: "cargando",
          name: "Planificación activa",
        },

        // Transporte activo
        transporte_historico_a: {
          solicitudes: "cargando",
          porcentaje: "cargando",
          name: "Transporte histórico",
        },
        transporte_activo_a: {
          solicitudes: "cargando",
          porcentaje: "cargando",
          name: "Planificación activa",
        },

        // viajes realizados
        planificaciones_t: {
          viajes_realizados: "",
          name: "Terrestre",
          image: require("@/assets/img/transporte/bus.png"),
        },
        planificaciones_a: {
          viajes_realizados: "",
          name: "Aéreo",
          image: require("@/assets/img/transporte/avion.png"),
        },

        //FF
        loading: true,
        range: {
          date_start: null,
          date_end: null,
          mask_start: null,
          mask_end: null
        }
      };
    },

    computed: {
      ...mapState("transporteModule", ["dashboard"]),
    },

    async created() {
      await this.getData();
    },

    watch: {
      "range.date_start"() {
        this.getData();
      },
      "range.date_end"() {
          this.getData();    
      },
    },
    methods: {
      ...mapActions("transporteModule", ["getDashboardTransporte"]),

      async getData() {
        this.loading = true;
        let data = {
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          tipo_rango: "0",
          fecha_inicio: this.range.date_start !== null ? this.range.mask_start : '',
          fecha_fin: this.range.date_end !== null ? this.range.mask_end : moment().endOf('month').format('YYYY-MM-DD'),
        };
        await this.getDashboardTransporte(data);
        this.fillProps();
        this.loading = false;
      },
      // llenando data de props
      fillProps() {
        // Ocupacion terrestre
        this.global_historica_t.ocupacion = isNaN(
          this.dashboard[0].ocupacion_historica
        )
          ? 0
          : this.ratio(this.dashboard[0].ocupacion_historica);
        this.global_historica_t.proximas_fechas = this.upcomingDates(
          this.dashboard[0].proximas_fechas
        );
        this.global_activa_t.ocupacion = isNaN(this.dashboard[0].ocupacion_activa)
          ? 0
          : this.ratio(this.dashboard[0].ocupacion_activa);
        this.global_activa_t.proximas_fechas = this.upcomingDates(
          this.dashboard[0].proximas_fechas
        );

        // Ocupacion aérea
        this.global_historica_a.ocupacion = isNaN(
          this.dashboard[1].ocupacion_historica
        )
          ? 0
          : this.ratio(this.dashboard[1].ocupacion_historica);
        this.global_historica_a.proximas_fechas = this.upcomingDates(
          this.dashboard[1].proximas_fechas
        );
        this.global_activa_a.ocupacion = isNaN(this.dashboard[1].ocupacion_activa)
          ? 0
          : this.ratio(this.dashboard[1].ocupacion_activa);
        this.global_activa_a.proximas_fechas = this.upcomingDates(
          this.dashboard[1].proximas_fechas
        );

        // Transporte histórico
        this.transporte_historico_t.solicitudes = isNaN(
          this.dashboard[0].solicitudes_historicas
        )
          ? 0
          : this.dashboard[0].solicitudes_historicas;
        this.transporte_historico_t.porcentaje = isNaN(
          this.dashboard[0].ocupacion_historica
        )
          ? 0
          : this.ratio(this.dashboard[0].ocupacion_historica);
        this.transporte_activo_t.solicitudes = isNaN(
          this.dashboard[0].solicitudes_activas
        )
          ? 0
          : this.dashboard[0].solicitudes_activas;
        this.transporte_activo_t.porcentaje = isNaN(
          this.dashboard[0].ocupacion_activa
        )
          ? 0
          : this.ratio(this.dashboard[0].ocupacion_activa);

        // Transporte histórico
        this.transporte_historico_a.solicitudes = isNaN(
          this.dashboard[1].solicitudes_historicas
        )
          ? 0
          : this.dashboard[1].solicitudes_historicas;
        this.transporte_historico_a.porcentaje = isNaN(
          this.dashboard[1].ocupacion_historica
        )
          ? 0
          : this.ratio(this.dashboard[1].ocupacion_historica);
        this.transporte_activo_a.solicitudes = isNaN(
          this.dashboard[1].solicitudes_activas
        )
          ? 0
          : this.dashboard[1].solicitudes_activas;
        this.transporte_activo_a.porcentaje = isNaN(
          this.dashboard[1].ocupacion_activa
        )
          ? 0
          : this.ratio(this.dashboard[1].ocupacion_activa);

        // Planificacion
        this.planificaciones_t.viajes_realizados =
          this.dashboard[0].viajes_realizados;
        this.planificaciones_a.viajes_realizados =
          this.dashboard[1].viajes_realizados;
      },
      ratio(porcentaje) {
        porcentaje = parseFloat(porcentaje);
        return porcentaje;
      },

      upcomingDates(fechas) {
        if (fechas.length != 0) {
          return fechas[0].fecha_viaje;
        } else {
          return "sin datos";
        }
      },

      //FF
      captureDateStart(date) {
        this.range.date_start = date;
        this.range.mask_start = moment(date).format('YYYY-MM-DD');
      },
      captureDateEnd(date) {
        this.range.date_end = date;
        this.range.mask_end = moment(date).format('YYYY-MM-DD');
      },
    },
  };
</script>
