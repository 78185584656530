<template>

  <div class="card card-shadow card-bars border-round-10">
    <div class="card-body">
      <div class="card-bars-title">Ocupación {{dashboard.name}}</div>
      <div class="card-bars-count my-2">
        <div class="card-bars-count-num pe-2">{{ratio(dashboard.ocupacion)}}%</div>
        <div 
          v-if="dashboard.name=='planificación activa'"
          class="card-bars-count-val">
          Salida: {{fechasProximas()}}
        </div>
      </div>
      <div class="card-bars-bar">
        <div class="card-bars-bar-back bg-blue-gray">
        </div>
        <div 
          class="card-bars-bar-front bg-green"
          v-if="dashboard.name=='planificación activa'"
          :style="`width: ${dashboard.ocupacion }%`">

        </div>
        <div 
          v-if="dashboard.name=='global histórica'"
          class="card-bars-bar-front bg-green"
          :style="`width: ${dashboard.ocupacion }%`">
        </div>
      </div>
    </div>
  </div>      
</template>

<script>
export default {
  props: {
    dashboard: {
      type:  Object,
    },
  },
  data(){
    return {

    };
  },
  methods:{
    fechasProximas(){
      if(this.dashboard.proximas_fechas.length != 0) {
        return this.dashboard.proximas_fechas[0].fecha_viaje;
      }
    },
    ratio(porcentaje) {
      porcentaje = parseFloat(porcentaje);
      return porcentaje;
    },
  }
};
</script>
