<template>

  <div class="card card-numbers border-round-10 border-0 card-shadow mb-3">
    <div class="card-body">
      <div class="card-numbers-head d-flex align-items-center color-main">
        <img class="card-numbers-head-icon me-2" :src="plan.image"/>
        <div class="card-numbers-head-num">{{plan.viajes_realizados}}</div>
        <div class="card-numbers-head-text">
          Viajes <br>
          Cerrados
        </div>
      </div>
      <div class="card-numbers-text mt-3">
        {{ intro_text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plan: {
      type:  Object,
    },
  },
  data() {
    return {
      //FF
      intro_text: "Transporte te permite acercar y agilizar el proceso de solicitudes. Te recomendamos revisar constantemente las estadísticas para llevar un registro de ellas y conocer sus estados."
    }
  }

};
</script>